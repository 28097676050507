import getLocalTimeChat from "../../../libs/getLocalTimeChat";
import getLocalTimeEvent from "../../../libs/getLocalTimeEvent";
import getTranslatedText from "../../../libs/getTranslatedText";
import Dot from "../../elements/ui/Dot";

const typeColors = {
  SORU: "text-blue-500",
  CEVAP: "text-green-500",
  FUAR: "text-yellow-500",
  "İŞ İLANI": "text-orange-500",
  YAZI: "text-emerald-700",
  KAPANMIŞ: "text-red-500",
  REHBER: "text-purple-500",
};

const ListingTagsList = ({ isNew, type, category, date, locale = "tr" }) => {
  return (
    <div className="flex flex-wrap items-center gap-2">
      {isNew && (
        <div className="text-sm font-medium text-secondaryDark">
          {getTranslatedText(locale, "YENİ", "listings.tags.new")}
        </div>
      )}
      {isNew && type && <Dot />}
      {type && (
        <div className={`text-sm font-medium ${typeColors[type]}`}>
          {getTranslatedText(locale, type, `listings.tags.${type}`)}
        </div>
      )}
      {type && category && <Dot />}
      {category && <div className="text-sm text-neutral-500">{category}</div>}
      {category && date && <Dot />}
      {date && (
        <div className="text-sm font-light text-neutral-500">
          {type === "FUAR"
            ? getLocalTimeEvent(locale, date)
            : getLocalTimeChat(locale, date, true)}
        </div>
      )}
    </div>
  );
};

export default ListingTagsList;
