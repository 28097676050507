import MiniAuthorBox from "../ui/MiniAuthorBox";
import BasicBottomReactions from "./BasicBottomReactions";
import ListingTagsList from "./ListingTagsList";
import Image from "next/image";
import Link from "next/link";

const PostMainListing = ({
  authorType,
  author,
  title,
  category,
  date,
  image,
  description,
  likeCount,
  commentCount,
  link,
  locale,
}) => {
  return (
    <Link className="js-postmain" href={link}>
      <div className="px-6 py-6 transition-colors duration-100 ease-out border border-gray-200 rounded-md hover:border-primaryLight">
        <MiniAuthorBox type={authorType} author={author} />
        {/* Title */}
        <h3 className="mt-4 text-xl font-medium">{title}</h3>
        {/* Tags */}
        <div className="mt-3">
          <ListingTagsList
            isNew={
              date && new Date(date) > new Date() - 86400000 /* 24 Hours */
            }
            category={category}
            date={date}
            locale={locale}
          />
        </div>
        {/* Post Image */}
        <div className="max-w-full mt-4 w-full rounded-md h-[200px] sm:h-[300px] relative overflow-hidden object-cover object-center">
          <Image
            src={process.env.NEXT_PUBLIC_IMAGE_URL + image}
            alt={title}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            placeholder="blur"
            blurDataURL={
              process.env.NEXT_PUBLIC_IMAGE_URL +
              image +
              "?key=blurry-thumbnail"
            }
          />
        </div>
        {/* Description */}
        <p className="mt-4 text-neutral-600">{description}</p>
        <BasicBottomReactions
          likeCount={likeCount}
          commentCount={commentCount}
          locale={locale}
        />
      </div>
    </Link>
  );
};

export default PostMainListing;
