const MainGuideLoader = () => {
  return (
    <div className="relative px-4 py-6 border border-gray-200 rounded-md sm:py-6 sm:px-6">
      <div className="flex items-center gap-4 animate-pulse">
        <div className="w-full delay-75 animate-pulse">
          <div className="w-1/2 h-3 rounded-sm bg-neutral-200"></div>
          <div className="w-16 h-2 mt-2 rounded-sm bg-neutral-100"></div>
        </div>
      </div>
      <div className="w-full my-4 rounded-sm h-60 bg-neutral-200 animate-pulse"></div>
      <div className="w-2/3 h-3 mt-2 delay-100 rounded-sm bg-neutral-100 animate-pulse"></div>
      <div className="flex items-center gap-2 mt-6 delay-200 rounded-sm animate-pulse">
        <div className="w-20 h-2 rounded-sm bg-neutral-100"></div>
        <div className="w-16 h-2 rounded-sm bg-neutral-200"></div>
        <div className="w-16 h-2 rounded-sm bg-neutral-100"></div>
      </div>
    </div>
  );
};

export default MainGuideLoader;
