const ControlMiniLoader = () => {
  return (
    <div className="flex items-center gap-4 px-3 py-2 border border-gray-200 rounded-md">
      <div className="w-16 h-16 bg-gray-100 rounded-md animate-pulse"></div>
      <div className="flex flex-col gap-2">
        <div className="w-24 h-2 rounded-sm bg-gray-50 animate-pulse"></div>
        <div className="w-40 h-3 bg-gray-200 rounded-sm animate-pulse"></div>
        <div className="w-16 h-2 bg-gray-100 rounded-sm animate-pulse"></div>
      </div>
    </div>
  );
};

export default ControlMiniLoader;
