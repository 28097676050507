import getTranslatedText from "./getTranslatedText";
import makeUpperCase from "./makeUpperCase";
import { DateTime } from "luxon";

const getLocalTimeEvent = (locale, time, fullDate = false) => {
  // Return FullDate if fullDate is true
  if (fullDate) {
    const dateString = DateTime.fromISO(time)
      .setLocale(locale)
      .toLocaleString(DateTime.DATETIME_HUGE);

    return dateString;
  }

  // If it's passed return "Passed Text"
  if (DateTime.fromISO(time).diffNow().as("days") < -1) {
    return getTranslatedText(locale, "Gerçekleşti", "listings.tags.passed");
  }
  // Relative time
  else {
    return makeUpperCase(
      DateTime.fromISO(time)
        .setLocale(locale)
        .toRelativeCalendar({ unit: "days" })
    );
  }
};

export default getLocalTimeEvent;
