import getTranslatedText from "../../../libs/getTranslatedText";
import { ChatBubble, Heart } from "akar-icons";

const BasicBottomReactions = ({ likeCount, commentCount, locale }) => {
  return (
    <div className="flex items-center mt-4 -ml-3 text-sm text-neutral-600">
      <div className="flex items-center gap-2 px-3 py-1 transition-colors duration-200 ease-out rounded-md hover:bg-red-50 hover:text-red-600">
        <Heart size={18} />
        <span>
          {likeCount}{" "}
          {getTranslatedText(
            locale,
            "beğeni",
            "listings.bottomReactions.likes"
          )}
        </span>
      </div>
      <div className="flex items-center gap-2 px-3 py-1 transition-colors duration-200 ease-out rounded-md hover:bg-red-50 hover:text-amber-600">
        <ChatBubble size={18} />
        <span>
          {commentCount}{" "}
          {getTranslatedText(
            locale,
            "yorum",
            "listings.bottomReactions.comments"
          )}
        </span>
      </div>
    </div>
  );
};

export default BasicBottomReactions;
